









import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component
export default class NotificationReadAction extends Vue {
  @Prop({ required: true, type: Boolean }) dismissed!: boolean;

  @Emit()
  makeRead(): void {
    return;
  }

  @Emit()
  makeUnread(): void {
    return;
  }
}
