import { ReferenceItem } from "@/services/types";

export enum NotificationType {
  EXPOSE_ORDERED = "EXPOSE_ORDERED",
  NEW_CONNECTION = "NEW_CONNECTION",
  TEASER_ACCEPTED = "TEASER_ACCEPTED",
  SEARCH_PROFILE_CREATED = "SEARCH_PROFILE_CREATED",
  CONTACT_NDA_SIGNED = "CONTACT_NDA_SIGNED",
  CONTACT_NDA_SENT = "CONTACT_NDA_SENT",
  GDRIVE_DISCONNECTED = "GDRIVE_DISCONNECTED",
  BEXIO_DISCONNECTED = "BEXIO_DISCONNECTED",
  EXPRESSION_OF_INTEREST_SENT = "EXPRESSION_OF_INTEREST_SENT",
  EXPRESSION_OF_INTEREST_FILLED = "EXPRESSION_OF_INTEREST_FILLED",
  EXPRESSION_OF_INTEREST_CHANGED = "EXPRESSION_OF_INTEREST_CHANGED",
  CONTACT_DISABLED = "CONTACT_DISABLED",
  CONTACT_DISABLED_REGISTRATION = "CONTACT_DISABLED_REGISTRATION",
  EXPOSE_REJECTED = "EXPOSE_REJECTED",
}

export default interface Notification {
  id?: number;
  historyId: number;
  userId: number;
  buyerId: number | null;
  dismissed: boolean;
  important: boolean;
  createdAt: number;
  project: ReferenceItem | null;
  contact: ReferenceItem | null;
  type: NotificationType;
}
