




































import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import NotificationItem from "@/components/notifications/NotificationItem.vue";
import Notification from "@/entity/Notification";

@Component({
  components: {
    NotificationItem,
  },
})
export default class NotificationsList extends Vue {
  @Prop({ default: undefined, type: String }) maxHeight!: string;
  @Prop({ default: false, type: Boolean }) showImportantAction!: boolean;
  @Prop({ required: true, type: Array }) notifications!: Notification[];

  @Emit()
  makeImportant(id: number): number {
    return id;
  }

  @Emit()
  makeUnimportant(id: number): number {
    return id;
  }

  @Emit()
  makeRead(id: number): number {
    return id;
  }

  @Emit()
  makeUnread(id: number): number {
    return id;
  }

  @Emit()
  notificationClicked(id: number): number {
    return id;
  }
}
