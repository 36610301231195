

































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import Notification, { NotificationType } from "@/entity/Notification";
import NotificationImportantAction from "@/components/notifications/NotificationImportantAction.vue";
import NotificationReadAction from "@/components/notifications/NotificationReadAction.vue";
import router from "@/routing/Router";
import Contact, { getContactFullName } from "@/entity/Contact";
import { Getter } from "vuex-class";

@Component({
  components: {
    NotificationImportantAction,
    NotificationReadAction,
  },
})
export default class NotificationItem extends Vue {
  @Prop({ required: true, type: Object }) notification!: Notification;
  @Prop({ default: false, type: Boolean }) showImportantAction!: boolean;

  @Getter("isAdmin", { namespace: "auth" }) isAdmin!: boolean;

  get notificationTypeName(): string {
    console.log("this.notification: ", this.notification);
    switch (this.notification.type) {
      case NotificationType.NEW_CONNECTION: {
        return this.$i18n
          .t("notifications.newConnection", {
            name: this.notification.contact?.name,
          })
          .toString();
      }
      case NotificationType.EXPOSE_ORDERED: {
        return this.$i18n.t("notifications.newPotentialBuyer").toString();
      }
      case NotificationType.SEARCH_PROFILE_CREATED: {
        return this.$i18n.t("notifications.searchProfileCreated").toString();
      }
      case NotificationType.CONTACT_NDA_SIGNED: {
        return this.$i18n.t("notifications.ndaSigned").toString();
      }
      case NotificationType.CONTACT_NDA_SENT: {
        return this.$i18n.t("notifications.ndaSent").toString();
      }
      case NotificationType.CONTACT_DISABLED: {
        return this.$i18n.t("notifications.contactDisabled").toString();
      }
      case NotificationType.CONTACT_DISABLED_REGISTRATION: {
        return this.$i18n
          .t("notifications.contactDisabledRegistration")
          .toString();
      }
      case NotificationType.TEASER_ACCEPTED: {
        return this.$i18n.t("notifications.teaserAccepted").toString();
      }
      case NotificationType.GDRIVE_DISCONNECTED: {
        return this.$i18n.t("notifications.gDriveDisconnected").toString();
      }
      case NotificationType.BEXIO_DISCONNECTED: {
        return this.$i18n.t("notifications.bexioDisconnected").toString();
      }
      case NotificationType.EXPRESSION_OF_INTEREST_SENT: {
        return this.$i18n.t("notifications.eoiSent").toString();
      }
      case NotificationType.EXPRESSION_OF_INTEREST_FILLED: {
        return this.$i18n.t("notifications.eoiFilled").toString();
      }
      case NotificationType.EXPRESSION_OF_INTEREST_CHANGED: {
        return this.$i18n.t("notifications.eoiChanged").toString();
      }
      case NotificationType.EXPOSE_REJECTED: {
        return this.$i18n.t("notifications.exposeRejected").toString();
      }
      default: {
        return "";
      }
    }
  }

  get onClick() {
    switch (this.notification.type) {
      case NotificationType.EXPOSE_ORDERED: {
        return async () => {
          if (this.notification.project === null) {
            return;
          }

          await router.push({
            name: "projectBuyers",
            params: {
              projectId: this.notification.project.id.toString(),
            },
            query: {
              source: "notification",
            },
          });
        };
      }
      case NotificationType.SEARCH_PROFILE_CREATED: {
        return async () => {
          if (this.notification.contact === null) {
            return;
          }

          await router.push({
            name: "contactSearchProfiles",
            params: {
              contactId: this.notification.contact.id.toString(),
            },
          });
        };
      }
      case NotificationType.CONTACT_NDA_SENT:
      case NotificationType.CONTACT_NDA_SIGNED:
      case NotificationType.CONTACT_DISABLED_REGISTRATION: {
        return async () => {
          if (this.notification.contact === null) {
            return;
          }

          await router.push({
            name: "contactDetail",
            params: {
              contactId: this.notification.contact.id.toString(),
            },
          });
        };
      }
      case NotificationType.TEASER_ACCEPTED: {
        return async () => {
          if (this.notification.project === null) {
            return;
          }

          await router.push({
            name: "projectBuyers",
            params: {
              projectId: this.notification.project.id.toString(),
            },
          });
        };
      }
      case NotificationType.BEXIO_DISCONNECTED:
      case NotificationType.GDRIVE_DISCONNECTED: {
        if (!this.isAdmin) {
          return false;
        }

        return async () => {
          await router.push({
            name: "settingsConnections",
          });
        };
      }
      default: {
        return false;
      }
    }
  }

  get projectName(): string | null {
    if (this.notification.project === null) {
      return null;
    }

    return this.notification.project.name;
  }

  get contactName(): string {
    if (this.notification.contact === null) {
      return "";
    }

    return this.notification.contact.name;
  }

  get title(): string {
    console.log("this.notificationTypeName: ", this.notificationTypeName);
    if (this.projectName === null) {
      return this.notificationTypeName;
    }

    return `${this.notificationTypeName} - ${this.projectName}`;
  }

  async handleOnClick(): Promise<void> {
    if (this.onClick) {
      await this.onClick();
    }

    this.notificationClicked();
  }

  @Emit()
  notificationClicked(): number {
    return this.notification.id as number;
  }

  @Emit()
  makeImportant(): number {
    return this.notification.id as number;
  }

  @Emit()
  makeUnimportant(): number {
    return this.notification.id as number;
  }

  @Emit()
  makeRead(): number {
    return this.notification.id as number;
  }

  @Emit()
  makeUnread(): number {
    return this.notification.id as number;
  }
}
