











import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component
export default class NotificationImportantAction extends Vue {
  @Prop({ required: true, type: Boolean }) important!: boolean;

  @Emit()
  makeImportant(): void {
    return;
  }

  @Emit()
  makeUnimportant(): void {
    return;
  }
}
